.gallery-img { 
    max-height: 50vw;
    max-width: 100%;
    object-fit: cover;
}

.gallery-container {
    display: flex;
    justify-content: center;
    margin: 0 auto ;
    padding-bottom: 10%;
}

.thumbs {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

@media screen and (max-width: 700px) {
    .gallery-img {
        max-height: 300px;
    }
    .thumbs {
        display: none;
    }
}

.img-del {
    float: left;
    font-size: 30px;
    z-index: 10000;
    margin-top: 2%;
}

.img-del:hover {
    cursor: pointer;
    font-size: 30px;
    z-index: 10000;
}

