.navbar{
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 80px;
    display: flex;
    text-decoration: none;
    justify-content: flex-end;
    position: sticky;
    max-width: 1600px;
}

.nav-item {
    font-size: 1.2em;
    padding: 1%;
    padding-bottom: 0%;
    list-style-type: none;
    max-height: 20px;
    font-weight: 500;
}

.nav-item:hover{
    transform: scale(1.1);
    cursor: pointer;
    color: gray;
}

a {
    text-decoration: none;
    color: #332517;
}


/* line  */
.x-p{
    display: none;
}


.hamburger {
    display: none;
}


.hide-me{
    display: none;
}

.blur-me {
    filter: blur(8px);
}



@media screen and (max-width: 950px){
    .navbar{
        display: none;
    }

    .hide-me{
        display: none;
    }

    .nav-menu {
        position: absolute;
        z-index: 1000;
        top: 0;
        right: 0;
        top: 60px;
        background-color: #FFF2D9;
        width: 0px;
        height: 100vh;
        transition: all 0.3s ease-in;
        overflow: hidden;
    }

    .nav-menu.active {
        width: 300px;
    }

    .x-p{
        display: block;
        float: right;
        padding-top: 1%;
    }

    .x-p :hover{
        cursor: pointer;
    }

    .nav-menu li {
        text-align: left;
        list-style-type: none;
        text-decoration: none;
        font-size: 25px;
        padding-top: 15%;
        margin-left: 10%;
    }


    .nav-menu a:visited {
        color: black;
    }

    .nav-menu li:hover {
        opacity: .6;
    }

    .nav-item:hover{
        transform: none;
        cursor: pointer;
        color: gray;
    }


    .hamburger {
        display: block;
        float: right;
    }

    .hamburger:hover {
        cursor: pointer;
    }

    .nav-hero {
        height: 80px;
    }
} 