.contact {
    padding: 0%;
}
.message-con {
    padding-bottom: 0%;
}

.con-mes {
    padding: 0;
    margin: 0;
    border: solid 1px;
}