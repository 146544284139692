.logo {
    max-width: 30%;
    width: 1400px;
    justify-self: center;
    padding-top: 10%;
    padding-bottom: 2%;
    z-index: 3;
}

.slpa {
    width: 1500px;
    max-width: 90%;
    justify-self: center;
    z-index: 2;
}


.container {
    display: grid;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    padding-bottom: 15%;
}

.circle {
    width: 700px;
    position: absolute;
    transform: translate(48%);
    width: 50%;
    max-width: 700px;
    z-index: 0;
    margin: 0 auto;
}

