html {
    background-color: #FFF2D9;
    margin: 0 auto;
    color: #332517;
    font-family: seravek;
    max-width: 1400px;
}

button:hover {
    cursor: pointer;
    opacity: .6;
}