.blog-post {
    border-radius: 10px;
    margin: 0 auto;
    display: block;
    text-align: center;
    background-color: rgb(105,105,105,0.5);
    max-width: 80%;
}

.blog-title {
    font-size: 3em;
    text-align: center;
    padding: 5%;
    padding-bottom: 0;
    margin-bottom: 0;
    text-decoration: underline;
}

.blog-body {
    font-size: 1.5em;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 2%;
    padding-top: 0;
    margin: 0%;
}

.blog-date {
    font-size: 1em;
    text-align: center;
    padding-bottom: 0;
    margin-top: 0;
}

@media screen and (max-width: 900px) {
    .blog-post {
        border-radius: 10px;
        margin: 0 auto;
        display: block;
        text-align: center;
        background-color: rgb(105,105,105,0.5);
        max-width: 100%;
    }
}
