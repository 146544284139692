.cont {
    background-color: #332517;
    border-radius: 15px;
    text-align: center;
    max-width: 80%;
    max-height: 40%;
    margin: 0 auto ;
}

.contact-container {
    justify-self: center;
    margin: 0 auto ;
    display: block;
    padding-bottom: 10%;
}

.con-title {
    margin-top: 0;
    font-size: 4.5em;
    text-align: center;
    justify-self: center;
    margin-bottom: 1%;
}

.inp {
    border-radius: 10px;
    background-color: #FFF5E9;
    width: 75%;
    margin: 1%;
    font-size: 1.5em;
    padding: 3%;
}

.top {
    margin-top: 10%;
}

.message {
    padding-bottom: 15%;
}

.btn {
    padding: 3%;
    font-size: 1.5em;
    border-radius: 10px;
    background-color: #FFF5E9;
    color: #332517;
    margin-bottom: 5%;
    margin-top: 3%;
    font-weight: 900;
}

@media screen and (max-width: 800px) {
    .con-title {
        font-size: 3rem;
    }
    
}